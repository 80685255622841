<template>
  <v-row>
        <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
           <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="5">
               <TextField dense number v-model="groupName" :title="'ชื่อกลุ่ม'" :placeholder="'ชื่อกลุ่ม'" :required="true" :rules="groupNameRules"/>
              <!-- <DropDownList dense :title="'ชื่อกลุ่ม'" :rules="formTypeRules" :placeholder="'ประเภท'" :required="true" :items="formTypeItems" item-text="formTypeName" return-object v-model="formType"/> -->
            </v-col>
             <v-col cols="12" md="4">
               <TextField dense number v-model="description" :title="'รายละเอียด'" :placeholder="'รายละเอียด'" :required="true" :rules="descriptionRules"/>
              <!-- <DropDownList dense :title="'ประจำปี'" :rules="yearRules" :placeholder="'โปรดเลือก'" :required="true" :items="yearItems" v-model="year" item-text="yearTH" item-value="yearEN"/> -->
            </v-col>
             <v-col cols="12" md="3">
                <label class="text-title-4 natural--text text--darken-4">ดำเนินการ
              <v-btn color="primary"  block @click="postGroup()" :loading="loading">เพิ่มกลุ่ม</v-btn>
               </label>
            </v-col>
          </v-row>
           </v-form>
         </v-card-text>
       </v-card>
        </v-col>
         <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
        <v-data-table :loading="loading" :items="groupItems" :headers="headers">
           <template v-slot:item.createDate="{item}">
         {{$dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
       </template>
          <template slot="no-data">
              <div class="text-center text-title-3 natural--text text--lighten-1 pt-2">
              ไม่มีข้อมูลเป้าหมาย
            </div>
          </template>
          <template v-slot:item.active="{ item }">
            <v-row align="center">
            <v-switch inset v-model="item.active" @change="patchGroup(item)" />
            </v-row>
       </template>
        </v-data-table>
         </v-card-text>
       </v-card>
         </v-col>
          <v-dialog v-model="confirmDeleteDialog" width="384" persistent>
           <ConfirmDeleteCard :title="'คุณต้องลบรายการนี้หรือไม่?'" :subtitle="''" :action="'ลบข้อมูลนี้'" @onClose="confirmDeleteDialog=false" @onConfirm="deleteSiteTarget()" />
        </v-dialog>
      </v-row>
</template>

<script>
import TextField from '@/components/common/TextField'
// import DropDownList from '@/components/common/DropDownList'
import { getGroup, postGroup, getYear, getSiteFormType, patchGroup } from '@/api/'
import ConfirmDeleteCard from '@/components/common/ConfirmDeleteCard'

export default {
  components: {
    TextField,
    // DropDownList,
    ConfirmDeleteCard
  },
  data () {
    return {
      loading: false,
      groupItems: [],
      yearItems: [],
      year: null,
      target: 0,
      formType: null,
      formTypeItems: [],
      groupName: null,
      description: null,
      headers: [
        { text: 'ชื่อกลุ่ม', value: 'groupName', class: 'primary lighten-5' },
        { text: 'รายละเอียด', value: 'description', class: 'primary lighten-5', width: '200px' },
        { text: 'สร้างเมื่อ', value: 'createDate', class: 'primary lighten-5', width: '100px' },
        { text: 'สร้างโดย', value: 'createByUserName', class: 'primary lighten-5', width: '150px' },
        { text: 'สถานะ', value: 'active', class: 'primary lighten-5', width: '100px' }
      ],
      valid: false,
      groupNameRules: [v => !!v || 'กรุณาระบุข้อมูล'],
      descriptionRules: [v => !!v || 'กรุณาระบุข้อมูล'],
      confirmDeleteDialog: false,
      currentItem: null
    }
  },
  methods: {
    patchGroup (item) {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      this.loading = true
      patchGroup({
        tenantId: tenant.tenantId,
        siteId: this.$route.params.siteId,
        id: item.id,
        etag: item._etag
      }, item, message => {
        if (message.data.code === 1) {
          this.getGroup()
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    postGroup () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        postGroup({
          tenantId: tenant.tenantId,
          siteId: this.$route.params.siteId,
          groupName: this.groupName,
          description: this.description
        }, message => {
          if (message.data.code === 1) {
            this.getGroup()
            this.$refs.form.reset()
          }
          this.loading = false
        }, error => {
          console.log(error)
          this.loading = false
        })
      }
    },
    async getGroup () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      this.loading = true
      try {
        const resp = await getGroup({
          tenantId: tenant.tenantId,
          siteId: this.$route.params.siteId
        })
        if (resp.data.code === 1) {
          this.groupItems = resp.data.result.items
        } else {
          this.groupItems = []
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async fetchData () {
      try {
        let resp = await getYear()
        if (resp.data.code === 1) {
          this.yearItems = resp.data.result
          this.year = [String(new Date().getFullYear())]
        }
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        resp = await getSiteFormType({
          tenantId: tenant.tenantId,
          siteId: this.$route.params.siteId,
          offset: 0,
          limit: 100,
          sortBy: 'formTypeName',
          sorDesc: 0
        })
        if (resp.data.code === 1) {
          this.formTypeItems = resp.data.result.items
          this.formType = []// this.buildArrayItem('formTypeId', resp.data.result.items)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
      this.getGroup()
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
